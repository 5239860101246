@use "../../abstracts/variables/colors";
@use "../../abstracts/variables/typography";

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid;
	border-color: colors.$color__border-button;
	border-radius: 3px;
	background: colors.$color__background-button;
	color: rgba(0, 0, 0, 0.8);
	line-height: 1;
	padding: 0.6em 1em 0.4em;

	&:hover {
		border-color: colors.$color__border-button-hover;
	}

	&:active,
	&:focus {
		border-color: colors.$color__border-button-focus;
	}
}

.back-to-top {
	font-family: typography.$font__header;

	&::before {
		content: "↑";
		display: inline-block;
		margin-right: 0.5em;
		transform: rotate(-90deg);
	}
}

.scroll-down {
	font-family: typography.$font__header;

	&::after {
		content: "↑";
		display: inline-block;
		margin-left: 0.5em;
		transform: rotate(90deg);
	}
}

.custom-cursor {
	position: fixed;
	opacity: 0;
	pointer-events: none;
	mix-blend-mode: difference;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: white;
	transition: transform 350ms ease;
	transform: translate(-50%, -50%) scale(.3);
	z-index: 1000;
  }
  .custom-cursor--link {
	transform: translate(-50%, -50%) scale(1);
  }