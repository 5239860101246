/*!
Theme Name: _s
Theme URI: https://underscores.me/
Author: Automattic
Author URI: https://automattic.com/
Description: Hi. I'm a starter theme called <code>_s</code>, or <em>underscores</em>, if you like. I'm a theme meant for hacking so don't use me as a <em>Parent Theme</em>. Instead try turning me into the next, most awesome, WordPress theme out there. That's what I'm here for.
Version: 1.0.0
Tested up to: 5.4
Requires PHP: 5.6
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: _s
Tags: custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned.

_s is based on Underscores https://underscores.me/, (C) 2012-2020 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal https://necolas.github.io/normalize.css/
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Generic
	- Normalize
	- Box sizing
# Base
	- Typography
	- Elements
	- Links
	- Forms
## Layouts
# Components
	- Navigation
	- Posts and pages
	- Comments
	- Widgets
	- Media
	- Captions
	- Galleries
# plugins
	- Jetpack infinite scroll
# Utilities
	- Accessibility
	- Alignments

--------------------------------------------------------------*/

// Import variables and mixins.
@use "abstracts/abstracts";

/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/

/* Normalize
--------------------------------------------- */
@use "generic/normalize";

/* Box sizing
--------------------------------------------- */
@use "generic/box-sizing";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@use "base/base";

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
// @import "layouts/content-sidebar"; // Uncomment this line for a sidebar on right side of your content.
// @import "layouts/sidebar-content"; // Uncomment this line for a sidebar on left side of your content.

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@use "components/components";

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/

/* Jetpack infinite scroll
--------------------------------------------- */
@use "plugins/jetpack/infinite-scroll";

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/

/* Accessibility
--------------------------------------------- */
@use "utilities/accessibility";

/* Alignments
--------------------------------------------- */
@use "utilities/alignments";
