@use "../../abstracts/variables/colors";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	cursor: none;
}

body {
	background: colors.$color__background-body; // Fallback for when there is no custom background color defined.
}
