@use "../../abstracts/variables/typography";

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	font-family: typography.$font__header;
	margin: 0;
}
