@use "sass:meta";
@use "../../abstracts/variables/colors";
@use "../../abstracts/variables/typography";

body,
button,
input,
select,
optgroup,
textarea {
	color: colors.$color__text-main;
	font-family: typography.$font__main;
	font-size: 1rem;
	line-height: typography.$font__line-height-body;
}

@include meta.load-css("headings");
@include meta.load-css("copy");

